.App:focus,
button:focus {
    outline: none;
}

.in-game-score {
    display: flex;
    justify-content: center;
    text-align: center;
}

.valid-words-remaining-container {
    margin-right: 20px;
}

.in-game-score .score-count {
    font-size: 2em;
    margin-bottom: 15px;
}

.show-undo-link {
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}
