.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3000;
}

.overlay[modal-state='visible'] {
  display: flex;
}

/*:host([open]) .overlay {
  display: flex;
}*/

.content {
  position: relative;
  border-radius: 8px;
  border: 1px solid #f6f7f8;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  animation: SlideIn 200ms;
  max-width: 500px;
  padding: 16px;
  box-sizing: border-box;
}

.content.closing {
  animation: SlideOut 200ms;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.CheatModal game-icon {
  position: fixed;
  user-select: none;
  cursor: pointer;
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}


.valid-word-remaining-result {
  display: flex
}

.valid-word-remaining-list {
  font-size: 1.6rem;
}

.valid-word-remaining-result-letter {
  border: 2px solid #d3d6da;
  border-radius: 3px;
  margin: 2px;
  font-size: 1.6rem;
  font-weight: 700;
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
}

.valid-letter-box-miss {
  border: 2px solid #787c7e;
  background-color: #787c7e;
  color: white;
}

.valid-letter-box-success {
  border: 2px solid #6aaa64;
  background-color: #6aaa64;
  color: white;
}

.valid-letter-box-partial-success {
  border: 2px solid #c9b458;
  background-color: #c9b458;
  color: white;
}