#keyboard-cont {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#keyboard-cont div {
  display: flex;
}

.special-buttons {
  margin-bottom: 20px;
  text-transform: none;
}

.second-row {
  margin: 0.5rem 0;
}

.keyboard-button {
  height: 50px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem;
  margin: 0 2px;
  cursor: pointer;
  text-transform: uppercase;
  border: 2px solid #d3d6da;
  background-color: #d3d6da;
  color: black;
}

#undo-button, #show-undo-results, #random-starting-word {
  text-transform: none;
  height: 40px;
}

.keyboard-button-success {
  border: 2px solid #6aaa64;
  background-color: #6aaa64;
  color: white;
}

.keyboard-button-partial-success {
  border: 2px solid #c9b458;
  background-color: #c9b458;
  color: white;
}

.keyboard-button-miss {
  border: 2px solid #787c7e;
  background-color: #787c7e;
  color: white;
}

.keyboard-button-miss .delete-icon path {
  fill: white;
}
