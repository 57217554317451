.settings-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 3000;
}

.settings-overlay[modal-state='visible'] {
  display: flex;
}

/*:host([open]) .overlay {
  display: flex;
}*/

.settings-overlay-content {
  position: relative;
  color: black;
  padding: 0 32px;
  max-width: 400px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  animation: SettingsSlideIn 200ms;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

@keyframes SettingsSlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(211, 214, 218);
    padding: 16px 0;
}

.text {
    padding-right: 8px;
}

.title {
    font-size: 18px;
}

.description {
    font-size: 12px;
    color: rgb(8 9 9);
}

.switch {
  height: 20px;
  width: 32px;
  vertical-align: middle;
  background: rgb(135, 138, 140);
  border-radius: 999px;
  display: block;
  position: relative;
}

.knob {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(100% - 4px);
  width: 50%;
  border-radius: 8px;
  background: white;
  transform: translateX(0);
  transition: transform 0.3s;
}


game-switch[checked='true'] .switch {
  background: #6aaa64;
}
game-switch[checked='true'] .knob {
  transform: translateX(calc(100% - 4px));
}
game-switch[disabled='true'] .switch {
  opacity: 0.5;
}
