header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0 16px;
    height: 50px;
    color: black;
    border-bottom: 1px solid #d3d6da;
    padding: 0px 16px;
    margin-bottom: 16px;
    
}

header .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.01em;
    text-align: center;
    left: 0;
    right: 0;
    pointer-events: none;
}

button.icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 4px;
}

#nav-button {
    padding-top: 2px;
}

.menu-left {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    width: 70px;
    justify-content: flex-start;
}