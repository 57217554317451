.letter-box {
  border: 2px solid #d3d6da;
  border-radius: 3px;
  margin: 2px;
  font-size: 1.3rem;
  font-weight: 700;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.filled-box {
  border: 2px solid black;
}

.letter-row {
  display: flex;
}

.letter-box-miss {
  border: 2px solid #787c7e;
  background-color: #787c7e;
  color: white;
}

.letter-box-success {
  border: 2px solid #6aaa64;
  background-color: #6aaa64;
  color: white;
}

.letter-box-partial-success {
  border: 2px solid #c9b458;
  background-color: #c9b458;
  color: white;
}

.letter-box-death {
  border: 2px solid #ec4646;
  background-color: #ec4646;
  color: white;
}

.letter-box-disqualified {
  border: 2px solid #3c3636;
  background-color: #3c3636;
  color: #3c3636;
  transition: 3s;
}

.letter-box-win {
  animation-name: Bounce;
  animation-duration: 1000ms;
}

.letter-box[data-animation='pop'] {
  animation-name: PopIn;
  animation-duration: 100ms;
}

@keyframes PopIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.letter-box[data-animation='flip-in'] {
  animation-name: FlipIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes FlipIn {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

.letter-box[data-animation='flip-out'] {
  animation-name: FlipOut;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes FlipOut {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0);
  }
}

.letter-box[data-animation='flip-game-over-begin'] {
  animation-name: FlipGameOverBegin;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes FlipGameOverBegin {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

.letter-box[data-animation='flip-game-over-end'] {
  animation-name: FlipGameOverEnd;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes FlipGameOverEnd {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0);
  }
}


@keyframes Bounce {
  0%, 20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}