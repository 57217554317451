#game-toaster {
    z-index: 4000;
}

.toaster {
    position: absolute;
    top: 124px;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    width: fit-content;
    max-width: 400px;
}

.toast {
  position: relative;
  margin: 16px;
  background-color: black;
  color: white;
  padding: 16px;
  border: none;
  border-radius: 4px;
  opacity: 1;
  /*transition: opacity 2500ms cubic-bezier(0.645, 0.045, 0.355, 1);*/
  font-weight: 700;
}
.fade[toastpurpose='survived'] {
  transition: opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 2000ms;
  opacity: 0;
}
.fade[toastpurpose='gameover'] {
  transition: opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 1000ms;
  opacity: 0;
}
.fade[toastpurpose='tempeliminated'] {
  transition: opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 3500ms;
  opacity: 0;
}
.fade[toastpurpose='eliminated'] {
  transition: opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 3000ms;
  opacity: 0;
}
.fade[toastpurpose='error'] {
  transition: opacity 2500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}
