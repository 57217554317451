.letter-row {
  display: flex;
}

.valid-words-remaining-count-at-row {
    position: absolute;
    width: 60px;
    left: calc(50% + 104px);
}

.valid-words-remaining-count-at-row.show-link-to-words-remaining {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}