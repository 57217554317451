#game-board {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*.letter-box {
  border: 2px solid #d3d6da;
  border-radius: 3px;
  margin: 2px;
  font-size: 2rem;
  font-weight: 700;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.filled-box {
  border: 2px solid black;
}

.letter-row {
  display: flex;
}*/

/*.letter-box-miss {
  border: 2px solid #787c7e;
  background-color: #787c7e;
  color: white;
}

.letter-box-success {
  border: 2px solid #6aaa64;
  background-color: #6aaa64;
  color: white;
}

.letter-box-partial-success {
  border: 2px solid #c9b458;
  background-color: #c9b458;
  color: white;
}
*/
