.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3000;
}

.overlay[modal-state='visible'] {
  display: flex;
}

/*:host([open]) .overlay {
  display: flex;
}*/

.content {
  position: relative;
  border-radius: 8px;
  border: 1px solid #f6f7f8;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  animation: SlideIn 200ms;
  max-width: 500px;
  padding: 16px;
  box-sizing: border-box;
}

.content.closing {
  animation: SlideOut 200ms;
}

.close-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.CheatModal game-icon {
  position: fixed;
  user-select: none;
  cursor: pointer;
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}

.container h1 {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
}

#statistics1,#statistics2 {
    display: flex;
    margin-bottom: 30px;
}

#guess-distribution {
    width: 80%;
}

.statistic-container {
    flex: 1;
    margin-left: 12px;
}

.statistic-container .statistic {
    font-size: 36px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05em;
    font-variant-numeric: proportional-nums;
}

.statistic-container .label {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.graph-container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
}

.graph-container .guess {
  width: 100px;
  text-align: right;
}

.graph-container .graph {
    width: 100%;
    height: 100%;
    padding-left: 4px;
}

.graph-container .graph .num-guesses {
    font-weight: bold;
    color: white;
}
.graph-container .graph .graph-bar {
    height: 100%;
    width: 0%;
    position: relative;
    background-color: gray;
    display: flex;
    justify-content: center;
}

.graph-container .graph .graph-bar.align-right {
    justify-content: flex-end;
    padding-right: 8px;
}

.graph-container .graph .graph-bar.highlight {
    background-color: #6aaa64;
}

.footer {
    display: flex;
    width: 100%;
}

.countdown {
    border-right: 1px solid black;
    padding-right: 12px;
    width: 50%;
}

.statistic.timer {
    font-variant-numeric: initial;
}

.share {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    width: 50%;
}

button#share-button {
    background-color: #6aaa64;
    color: white;
    font-family: inherit;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
    width: 80%;
    font-size: 20px;
    height: 52px;
    -webkit-filter: brightness(100%);
}

button#share-button game-icon {
    width: 24px;
    height: 24px;
    padding-left: 8px;
}
