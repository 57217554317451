.game-over-section {
    margin-top: 20px;
    animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.game-over-message {
    /*display: flex;*/
    /*justify-content: center;*/
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

.share-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
}


button#share-button-home-page{
    background-color: #6aaa64;
    color: white;
    border: none;
}

button#stats-button-home-page {
    background-color: #787c7e;
    color: white;
    border: none;
}

button#share-button-home-page, button#stats-button-home-page {
    font-family: inherit;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0,0,0,0.3);
    font-size: 15px;
    height: 40px;
    width: 100px;
    -webkit-filter: brightness(100%);
    margin: 5px;
}

button#share-button-home-page game-icon, button#stats-button-home-page game-icon {
    width: 24px;
    height: 24px;
    padding-left: 8px;
}




